import { gql } from '@apollo/client/core';
import { query, GetPresentationInput, Presentation } from 'app/graphql';

export const PresentationQuery = gql`
  query presentation($input: GetPresentationInput!) {
    presentation(input: $input) {
      id
      name
      firstSlide
      slides
      layoutId
      formula {
        id
        defaultElementVariations
        defaultSlideOptions
      }
      palette {
        id
        colorSets
      }
      textFont {
        id
        fontId
        fontFamily
        ... on WebFont {
          webfont
        }
        ... on FileFont {
          fontFileUrl
        }
      }
      headingFont {
        id
        fontId
        fontFamily
        ... on WebFont {
          webfont
        }
        ... on FileFont {
          fontFileUrl
        }
      }
      logoLightBg
      logoDarkBg
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const presentationQuery = (input: GetPresentationInput) =>
  query<{ presentation: Presentation }>(PresentationQuery, {
    variables: { input },
  });
