import { NgModule, Injector } from '@angular/core';
import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { AppComponent } from 'app/app.component';
import { ApolloProvider, ApolloModule } from 'app/graphql';
import { RenderModule, RenderEvoModule } from '@slidebean/render';
import {
  Location,
  LocationStrategy,
  PathLocationStrategy,
} from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { setAppInjector } from 'app-injector';
import {
  RenderPluginsModule,
  SLIDE_HEIGHT,
  SLIDE_WIDTH,
} from './render/render-plugins.module';

@NgModule({
  declarations: [AppComponent],
  imports: [
    ApolloModule,
    BrowserModule,
    HammerModule,
    RenderPluginsModule,
    RenderModule,
    RenderEvoModule.forRoot({
      slideHeight: SLIDE_HEIGHT,
      slideWidth: SLIDE_WIDTH,
    }),
    TranslateModule.forRoot(),
  ],
  providers: [
    Location,
    ApolloProvider,
    { provide: LocationStrategy, useClass: PathLocationStrategy },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(injector: Injector) {
    setAppInjector(injector);
  }
}
