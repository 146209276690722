import { FactoryProvider } from '@angular/core';
import { APOLLO_OPTIONS, ApolloModule } from 'apollo-angular';
import { createHttpLink } from '@apollo/client/link/http';
import { setContext } from '@apollo/client/link/context';
import { InMemoryCache } from '@apollo/client/cache';
import possibleTypes from './codegen/possible-types.json';
import { environment } from 'environments';
import { appInjector } from 'app-injector';
import { AuthService } from './auth.service';

function createApollo() {
  const cache = new InMemoryCache({
    dataIdFromObject: object => (object as any).id,
    possibleTypes,
  });

  const httpLink = createHttpLink({ uri: environment.THEWALL_URL });

  const authLink = setContext((_, { headers }) => {
    const authService = appInjector()?.get(AuthService);
    const roleToken = authService?.roleToken;
    return {
      headers: {
        ...headers,
        ...(roleToken ? { 'x-rt': roleToken } : {}),
      },
    };
  });

  return {
    link: authLink.concat(httpLink),
    cache,
  };
}

export const ApolloProvider: FactoryProvider = {
  provide: APOLLO_OPTIONS,
  useFactory: createApollo,
};

export { ApolloModule };
