import { NgModule } from '@angular/core';
import { HeadingPluginModule } from '@slidebean/slide-elements/heading';
import { AudioPluginModule } from '@slidebean/slide-elements/audio';
import { BackgroundPluginModule } from '@slidebean/slide-elements/background';
import { BarChartPluginModule } from '@slidebean/slide-elements/bar-chart';
import { CirclePluginModule } from '@slidebean/slide-elements/circle';
import { CodePluginModule } from '@slidebean/slide-elements/code';
import { CoverPluginModule } from '@slidebean/slide-elements/cover';
import { FooterPluginModule } from '@slidebean/slide-elements/footer';
import { FunnelPluginModule } from '@slidebean/slide-elements/funnel';
import { GoogleDocPluginModule } from '@slidebean/slide-elements/google-doc';
import { IconPluginModule } from '@slidebean/slide-elements/icon';
import { ImagePluginModule } from '@slidebean/slide-elements/image';
import { LinePluginModule } from '@slidebean/slide-elements/line';
import { NumberPluginModule } from '@slidebean/slide-elements/number';
import { ParagraphPluginModule } from '@slidebean/slide-elements/paragraph';
import { PieChartPluginModule } from '@slidebean/slide-elements/pie-chart';
import { QuotePluginModule } from '@slidebean/slide-elements/quote';
import { SquarePluginModule } from '@slidebean/slide-elements/square';
import { TablePluginModule } from '@slidebean/slide-elements/table';
import { TimelinePluginModule } from '@slidebean/slide-elements/timeline';
import { VennPluginModule } from '@slidebean/slide-elements/venn';
import { VideoPluginModule } from '@slidebean/slide-elements/video';
import { ChartModule } from '@slidebean/chart';
import { NounProjectIconModule } from '@slidebean/noun-project-icon';
import { LineChartPluginModule } from '@slidebean/slide-elements/line-chart';

export const SLIDE_HEIGHT = 1000;
export const SLIDE_WIDTH = 1600;

@NgModule({
  imports: [
    NounProjectIconModule.forRoot(),
    ChartModule.forRoot(),
    HeadingPluginModule.forRoot(),
    AudioPluginModule.forRoot(),
    BackgroundPluginModule.forRoot(),
    BarChartPluginModule.forRoot(),
    LineChartPluginModule.forRoot(),
    PieChartPluginModule.forRoot(),
    CirclePluginModule.forRoot(),
    CodePluginModule.forRoot(),
    CoverPluginModule.forRoot(),
    FooterPluginModule.forRoot(),
    FunnelPluginModule.forRoot(),
    GoogleDocPluginModule.forRoot(),
    IconPluginModule.forRoot(),
    ImagePluginModule.forRoot(),
    LinePluginModule.forRoot({
      slideHeight: SLIDE_HEIGHT,
      slideWidth: SLIDE_WIDTH,
    }),
    NumberPluginModule.forRoot(),
    ParagraphPluginModule.forRoot(),
    QuotePluginModule.forRoot(),
    SquarePluginModule.forRoot(),
    TablePluginModule.forRoot(),
    TimelinePluginModule.forRoot(),
    VennPluginModule.forRoot(),
    VideoPluginModule.forRoot(),
  ],
})
export class RenderPluginsModule {}
