import { Injector } from '@angular/core';

let injectorRef: Injector;

export function appInjector(): Injector {
  return injectorRef;
}

export function setAppInjector(injector: Injector): void {
  injectorRef = injector;
}
