import { Apollo } from 'apollo-angular';
import { OperationVariables } from '@apollo/client/core';
import { DocumentNode } from 'graphql';

export const query =
  <T>(
    query: DocumentNode,
    { variables }: { variables?: OperationVariables } = {},
  ) =>
  (apollo: Apollo): Promise<T> => {
    return apollo
      .query<T>({
        query,
        variables,
        errorPolicy: 'all',
      })
      .toPromise()
      .then(result => {
        if (result.error) throw result.error;
        if (result.errors?.length) throw result.errors[0];
        return result.data;
      });
  };
