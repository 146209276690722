import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private _roleToken: string;

  public get roleToken(): string {
    return this._roleToken;
  }

  public setRoleToken(token: string): void {
    this._roleToken = token;
  }
}
