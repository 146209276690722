import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Apollo } from 'apollo-angular';
import { Slide } from '@slidebean/sdk/presentation';
import { Presentation } from 'app/graphql';
import { presentationQuery } from './presentation.query';
import { AuthService } from './graphql/auth.service';
import { NounProject } from '@slidebean/noun-project-icon';

function loadImageUrl(url: string): Promise<HTMLImageElement> {
  const TIMEOUT_SECONDS = 10;
  return Promise.race([
    new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = (): void => resolve(img);
      img.onerror = (error): void => reject(error);
      img.crossOrigin = 'Anonymous';
      img.src = url;
    }),
    new Promise((_, reject) => {
      setTimeout(
        () =>
          reject(
            `Image ${url} took longer than ${TIMEOUT_SECONDS} seconds to load`,
          ),
        TIMEOUT_SECONDS * 1000,
      );
    }),
  ]) as Promise<HTMLImageElement>;
}

@Component({
  selector: 'app-root', // tslint:disable-line:component-selector
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  slides: Slide[] = [];
  cheese = false;
  error: any;
  responsive: boolean;
  presentation: Presentation;

  constructor(
    private location: Location,
    private apollo: Apollo,
    private auth: AuthService,
    private nounProject: NounProject,
  ) {}

  private getLocationParams(): {
    presentationId: string;
    pageNumber: number;
    pageSize: number;
    responsive: boolean;
    exportsKey: string;
  } {
    const match = /^\/?([0-9A-Za-z]+)/.exec(this.location.path());
    if (!match || !match[1]) return null;

    const query = this.location.path().split('?')[1] || '';
    const params = new URLSearchParams(query);

    return {
      presentationId: match[1],
      pageNumber: parseInt(params.get('pageNumber'), 10),
      pageSize: parseInt(params.get('pageSize'), 10),
      responsive: params.get('responsive') === 'true',
      exportsKey: params.get('exportsKey') || null,
    };
  }

  ngOnInit(): void {
    const params = this.getLocationParams();
    if (!params) return;

    const { presentationId, pageNumber, pageSize, responsive, exportsKey } =
      params;

    this.responsive = responsive;
    const includeSlides = !(pageSize === 1 && pageNumber === 0);

    if (exportsKey) {
      this.auth.setRoleToken(exportsKey);
    }

    Promise.resolve()
      .then(() => {
        return presentationQuery({ presentationId })(this.apollo).then(
          result => result.presentation,
        );
      })
      .then((presentation: Presentation) => {
        this.presentation = presentation;

        if (!includeSlides) {
          this.slides = [presentation.firstSlide];
        } else {
          this.slides = presentation.slides;
          // Pagination
          if (
            !isNaN(pageSize) &&
            pageSize > 0 &&
            !isNaN(pageNumber) &&
            pageNumber >= 0
          ) {
            this.slides = this.slides.slice(
              pageNumber * pageSize,
              (pageNumber + 1) * pageSize,
            );
          }
        }
      })
      .then(() => {
        const imagesPromise = Promise.resolve().then(() => {
          // Gather all images and wait for them to load
          const images = [];
          this.slides.forEach((slide: Slide) => {
            slide.elements.forEach(component => {
              if (
                component.type === 'background' ||
                component.type === 'image'
              ) {
                if (component.data.image) {
                  images.push(component.data.image.url);
                }
              }
            });
          });

          if (images.length > 0) {
            return Promise.all(
              images.map(url =>
                loadImageUrl(url).catch(error => {
                  console.error('Could not load image with url ', url, error);
                }),
              ),
            );
          }
        });

        const iconsPromise = Promise.resolve().then(() => {
          // Gather all icons, request their urls, and fetch them
          const icons = [];
          this.slides.forEach((slide: Slide) => {
            slide.elements.forEach(component => {
              if (
                component.type === 'image' &&
                component.data.icon &&
                component.data.icon.source === 'NounProject'
              ) {
                icons.push(component.data.icon.id);
              }
            });
          });

          if (icons.length > 0) {
            return Promise.all(
              icons.map(iconId =>
                this.nounProject.getIconById(iconId).catch(error => {
                  console.error(
                    'Could not fetch NounProject icon with id ',
                    iconId,
                    error,
                  );
                }),
              ),
            );
          }
        });

        return Promise.all([imagesPromise, iconsPromise]);
      })
      .then(() => {
        setTimeout(() => (this.cheese = true), 2000);
      })
      .catch(error => {
        console.error(error);
        this.error = error || true;
      });
  }

  trackSlideBy(index: number, slide: Slide): string {
    return slide.id;
  }
}
