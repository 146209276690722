<div
  *ngIf="presentation && slides"
  id="render-wrapper"
  [class.fixed-width]="!responsive"
>
  <ng-template
    ngFor
    [ngForOf]="slides"
    [ngForTrackBy]="trackSlideBy"
    let-slide
    let-index="index"
  >
    <sb-slide-viewer
      *ngIf="!!presentation.layoutId"
      [hiRes]="true"
      [lazyLoad]="false"
      [slide]="slide"
      [currentIndex]="0"
      [currentStep]="999"
      [colorSetOffset]="index"
      [layoutId]="presentation.layoutId"
      [palette]="presentation.palette"
      [textFont]="presentation.textFont"
      [headingFont]="presentation.headingFont"
      [logoLightBg]="presentation.logoLightBg"
      [logoDarkBg]="presentation.logoDarkBg"
    ></sb-slide-viewer>
    <sb-evo-slide-viewer
      *ngIf="!presentation.layoutId"
      [lazyLoad]="false"
      [slide]="slide"
      [colorSetOffset]="index"
      [formula]="presentation.formula"
      [palette]="presentation.palette"
      [textFont]="presentation.textFont"
      [headingFont]="presentation.headingFont"
      [logoLightBg]="presentation.logoLightBg"
      [logoDarkBg]="presentation.logoDarkBg"
    ></sb-evo-slide-viewer>

    <div data-slide-notes [innerHTML]="slide.notes"></div>
  </ng-template>

  <div *ngIf="cheese" id="url2png-cheese">
    <div id="cheese"></div>
  </div>
</div>

<div *ngIf="!!error" id="no-cheese">No cheese for you!</div>
